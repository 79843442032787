<script setup lang="ts">
import { useRouter } from "#app";
import { useAuthStore } from "~/store/auth/auth.store";
import SvgIcon from "~~/components/Common/SvgIcon.vue";


const nav = ref(null);
const router = useRouter();
const authStore = useAuthStore();
const tab = ref<string>(router.currentRoute.value.fullPath);

watch(router.currentRoute, () => {
  tab.value = router.currentRoute.value.fullPath;
});
watch(router.currentRoute, () => {
  tab.value = router.currentRoute.value.fullPath;
});

const config = useRuntimeConfig();

/** BUBBLE_APP_URL must be like this: https://base-url.com/ **/
function getUrl(path_url: string): string {
  return config.public.BUBBLE_APP_URL + path_url;
}
</script>

<template>
  <div class="navbar">
    <h1 class="company_title text-black">
      {{ authStore.user?.main_entity_id?.name }}
    </h1>

    <section class="section">
      <nav>
        <!--        <nuxt-link class="tab" :data-tab="tab.includes('/catalog')" to="/catalog">-->
        <!--          <img src="/icons/emojis/maison.svg" alt="">-->
        <!--          <span class="body_med">Accueil</span>-->
        <!--        </nuxt-link>-->
        <!--        <nuxt-link class="tab" :data-tab="tab.includes('/catalog')" to="/catalog">-->
        <!--          <img src="/icons/emojis/maison.svg" alt="">-->
        <!--          <span class="body_med">Accueil</span>-->
        <!--        </nuxt-link>-->

        <nuxt-link class="tab" :data-tab="tab.includes('/home')" to="/home">
          <SvgIcon :name="tab.includes('/home') ? 'home-plain-menu' : 'home-menu'" class="h-[16px] w-[16px]"
            :class="tab.includes('/home') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Accueil</span>
        </nuxt-link>
        <nuxt-link class="tab" :data-tab="tab.includes('/catalog')" to="/catalog">
          <SvgIcon :name="tab.includes('/catalog') ? 'search-plain-menu' : 'search-menu'" class="h-[16px] w-[16px]"
            :class="tab.includes('/catalog') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Catalogue</span>
        </nuxt-link>
        <nuxt-link class="tab" :data-tab="tab.includes('/my-trainings')" to="/my-trainings">
          <SvgIcon :name="tab.includes('/my-trainings') ? 'graduate-cap-plain-menu' : 'graduate-cap-menu'"
            class="h-[16px] w-[16px]"
            :class="tab.includes('/my-trainings') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Mes formations</span>
        </nuxt-link>
      </nav>
    </section>
    <section v-if="!authStore.isCompanyAdmin && (authStore.isTrainingPlanAdmin || authStore.isTrainingAdmin)"
      class="section">
      <span class="section_title">Référent</span>
      <nav>
        <nuxt-link v-if="authStore.isTrainingAdmin" class="tab" :data-tab="tab.includes('/my-catalog')" to="/my-catalog">
          <SvgIcon :name="tab.includes('/my-catalog') ? 'books-plain-menu' : 'books-menu'" class="h-[16px] w-[16px]"
            :class="tab.includes('/my-catalog') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Gérer mes formations</span>
        </nuxt-link>
        <nuxt-link v-if="authStore.isTrainingPlanAdmin || authStore.isTrainingAdmin" class="tab"
          :data-tab="tab.includes('/my-sessions')" to="/my-sessions">
          <SvgIcon :name="tab.includes('/my-sessions') ? 'calendar-plain-menu' : 'calendar-menu'"
            class="h-[16px] w-[16px]"
            :class="tab.includes('/my-sessions') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Organiser une session</span>
        </nuxt-link>
      </nav>
    </section>

    <section v-if="authStore.isCompanyAdmin || authStore.isGroupAdmin || authStore.isManager || authStore.isGroupViewer"
      class="section">
      <span class="section_title" v-if="authStore.isManager && !(authStore.isCompanyAdmin || authStore.isGroupAdmin)
        ">Mon équipe</span>
      <span class="section_title" v-else>Admin</span>
      <nav>
        <nuxt-link v-if="authStore.isCompanyAdmin" class="tab" :data-tab="tab.includes('/dashboard')" to="/dashboard">
          <SvgIcon :name="tab.includes('/dashboard') ? 'chart-pie-plain-menu' : 'chart-pie-menu'"
            class="h-[16px] w-[16px]"
            :class="tab.includes('/dashboard') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Tableau de bord</span>
        </nuxt-link>
        <nuxt-link class="tab" :data-tab="tab.includes('/requests')" to="/requests">
          <SvgIcon :name="tab.includes('/requests') ? 'request-plain-menu' : 'request-menu'" class="h-[16px] w-[16px]"
            :class="tab.includes('/requests') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Demandes</span>
        </nuxt-link>
        <nuxt-link class="tab" :data-tab="tab.includes('/training-plan')" to="/training-plan">
          <SvgIcon :name="tab.includes('/training-plan') ? 'target-plain-menu' : 'target-menu'" class="h-[16px] w-[16px]"
            :class="tab.includes('/training-plan') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Plan de formation</span>
        </nuxt-link>
        <nuxt-link class="tab" :data-tab="tab.includes('/habilitations')" to="/habilitations">
          <SvgIcon :name="tab.includes('/habilitations') ? 'graduate-cap-plain-menu' : 'graduate-cap-menu'"
            class="h-[16px] w-[16px]"
            :class="tab.includes('/habilitations') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Habilitations</span>
        </nuxt-link>
        <nuxt-link v-if="authStore.isCompanyAdmin || authStore.isGroupAdmin" class="tab"
          :data-tab="tab.includes('/company-catalog')" to="/company-catalog">
          <SvgIcon :name="tab.includes('/company-catalog') ? 'books-plain-menu' : 'books-menu'" class="h-[16px] w-[16px]"
            :class="tab.includes('/company-catalog') ? 'text-primary-blue-color' : 'text-primary-color'" />

          <span class="body_med">Gestion catalogue</span>
        </nuxt-link>
        <!-- <a v-if="authStore.isCompanyAdmin" class="tab" :href="getUrl('contents_index')">
        <img src="/icons/emojis/livres.svg" alt="">
        <span class="body_med">Gestion catalogue</span>
      </a>-->
        <!--        <nuxt-link class="tab" :data-tab="tab.includes('/users')" to="/users">-->
        <!--          <img src="/icons/emojis/profil_user.svg" alt="">-->
        <!--          <span class="body_med">Utilisateurs</span>-->
        <!--        </nuxt-link>-->
        <nuxt-link class="tab" :data-tab="tab.includes('/users')" to="/users">
          <SvgIcon :name="tab.includes('/users') ? 'group-plain-menu' : 'group-menu'" class="h-[16px] w-[16px]"
            :class="tab.includes('/users') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Utilisateurs</span>
        </nuxt-link>
        <nuxt-link v-if="authStore.isCompanyAdmin" class="tab" :data-tab="tab.includes('/settings')" to="/settings">
          <SvgIcon :name="tab.includes('/settings') ? 'settings-plain-menu' : 'settings-menu'" class="h-[16px] w-[16px]"
            :class="tab.includes('/settings') ? 'text-primary-blue-color' : 'text-primary-color'" />
          <span class="body_med">Paramètres</span>
        </nuxt-link>
      </nav>
    </section>
  </div>
</template>

<style scoped lang="scss">
@import "~~/assets/scss/texts";

.navbar {
  @extend .body_med;
  @apply fixed flex flex-col h-full gap-[18px] px-[6px];
  background: var(--grey-light);
  width: var(--navbar_width);
  min-width: var(--navbar_width);

  border: 1px solid var(--grey-lightest);
}

@media (max-width: 768px) {
  .navbar {
    @apply hidden;
  }
}

.company_title {
  @apply p-[18px];
}

.section_title {
  @extend .tight_bold;
  @apply pl-[18px] pb-[6px] text-tertiary-color uppercase;
}

.section {
  nav {
    @extend .body_demi;
    @apply grid inline-block gap-[3px];
  }

  img {
    @apply inline-block mr-[6px];
    width: 20px;
    height: 20px;
  }
}

.tab {
  @apply flex text-secondary-color px-[18px] py-[8px] gap-2 rounded-[8px] items-center;

  &[data-tab="true"] {
    @apply bg-light-blue-color text-primary-blue-color;
  }

  &:not([data-tab="true"]):hover {
    background: var(--grey-lightest);
  }
}</style>
