<script setup lang="ts">
import { useRouter } from "#app";
import { useAuthStore } from "~/store/auth/auth.store";
import SvgIcon from "../Common/SvgIcon.vue";

const nav = ref(null);
const router = useRouter();
const authStore = useAuthStore();
const tab = ref<string>(router.currentRoute.value.fullPath);

watch(router.currentRoute, () => {
  tab.value = router.currentRoute.value.fullPath;
});
watch(router.currentRoute, () => {
  tab.value = router.currentRoute.value.fullPath;
});

function goToPage(page: string) {
  router.push(page);
}
</script>

<template>
  <div class="navbar_mobile__container">
    <div class="navbar-container">
      <div @click="goToPage('home')" class="tab svg-pulse">
        <SvgIcon v-show="tab.includes('/home')" name="home_on" class="img__size"></SvgIcon>
        <SvgIcon v-show="!tab.includes('/home')" name="home" class="img__size"></SvgIcon>
        <span>Accueil</span>
      </div>
      <div @click="goToPage('catalog')" class="tab svg-pulse">
        <SvgIcon v-show="tab.includes('/catalog')" name="book_on" class="img__size"></SvgIcon>
        <SvgIcon v-show="!tab.includes('/catalog')" name="book" class="img__size"></SvgIcon>
        <span>Catalogue</span>
      </div>
      <div @click="goToPage('my-trainings')" class="tab__my_trainings svg-pulse">
        <SvgIcon v-show="tab.includes('/my-trainings')" name="graduation_cap" class="img__size"></SvgIcon>
        <SvgIcon v-show="!tab.includes('/my-trainings')" name="graduation_cap_on" class="img__size"></SvgIcon>
        <span>Mes formations</span>

      </div>
      <div v-if="authStore.isTrainingPlanAdmin" @click="goToPage('my-sessions')" class="tab svg-pulse">
        <SvgIcon v-show="tab.includes('/my-sessions')" name="calendar" class="img__size"></SvgIcon>
        <SvgIcon v-show="!tab.includes('/my-sessions')" name="date" class="img__size"></SvgIcon>
        <span>Mes Sessions</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "/assets/scss/texts";

.navbar-container {
  @apply fixed py-2 flex h-[70px] w-full bottom-0 z-[500] ;
  background: var(--white-main);
  box-shadow: var(--shadow-soft)
}

.tab {
  @apply flex meta justify-center flex-col items-center text-secondary-color;
  flex: 1 0 0;
}

.tab__my_trainings {
  @apply flex meta justify-center flex-col items-center text-secondary-color;
  flex: 1.2 0 0;
}

.img__size {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.navbar_mobile__container {
  @apply hidden;
}

@media (max-width: 768px) {
  .navbar_mobile__container {
    @apply block;
  }
}

.svg-pulse:active {
  border-radius: 50%;
  animation: pulse 0.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
</style>
